export type IntegrationsV2 =
  | 'liveAgent'
  | 'cognigy-chat'
  | 'oresundsbron-mail'
  | 'genesys'
  | 'zendesk'
  | 'puzzel-chat'

export type IntegrationV2 = {
  name: string
  key: IntegrationsV2
  color: string
  configV2: true
  url: string
}

export const textIntegrationsV2: IntegrationV2[] = [
  {
    color: '#59195D',
    key: 'liveAgent',
    name: 'LiveAgent',
    configV2: true,
    url: 'liveagent',
  },
  {
    color: '#59195D',
    key: 'cognigy-chat',
    name: 'cognigy-chat',
    configV2: true,
    url: 'cognigy-chat',
  },
  {
    configV2: true,
    color: '#2980c9',
    url: 'oresundsbron-mail',
    key: 'oresundsbron-mail',
    name: 'OB mail',
  },
  {
    color: '#FF4F1F',
    key: 'genesys',
    name: 'Genesys',
    url: 'genesys',
    configV2: true,
  },
  {
    color: '#FF4F1F',
    key: 'genesys',
    name: 'Genesys',
    url: 'genesys',
    configV2: true,
  },
  {
    color: '#16140d',
    key: 'zendesk',
    name: 'Zendesk',
    configV2: true,
    url: 'zendesk',
  },
  {
    color: '#ff375b',
    key: 'puzzel-chat',
    name: 'Puzzel Chat',
    configV2: true,
    url: 'puzzel-chat',
  },
]
