import { ErrorBoundary } from '@capturi/react-utils'
import { Spinner } from '@capturi/ui-components'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router'

import Organization from './Organization'
import Agents from './tabs/Agents'
import ApiWebhooks from './tabs/ApiAndWebhooks/Index'
import AuditLog from './tabs/AuditLog'
import CustomerData from './tabs/CustomerData'
import DeletionPolicy from './tabs/DeletionPolicy'
import Importer from './tabs/Importer'
import RepeatCalls from './tabs/RepeatCalls'
import Teams from './tabs/Teams'
import Users from './tabs/Users'

const OrganizationPageRoutes: React.FC = () => {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<Spinner mx="auto" />}>
        <Routes>
          <Route path="/" element={<Organization />}>
            <Route index element={<Navigate to="users" replace />} />

            <Route path="users" element={<Users />} />
            <Route path="agents" element={<Agents />} />
            <Route path="teams" element={<Teams />} />
            <Route path="api" element={<ApiWebhooks />} />
            <Route
              path="deletion-policy"
              element={
                <React.Suspense fallback={<Spinner mx="auto" />}>
                  <DeletionPolicy />
                </React.Suspense>
              }
            />
            <Route path="repeat-calls" element={<RepeatCalls />} />
            <Route path="audit-log" element={<AuditLog />} />
            <Route path="importer" element={<Importer />} />
            <Route path="customer-data" element={<CustomerData />} />
          </Route>
        </Routes>
      </React.Suspense>
    </ErrorBoundary>
  )
}

export default OrganizationPageRoutes
