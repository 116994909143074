import { useCurrentUser } from '@capturi/core'
import {
  type IntegrationV2,
  type IntegrationsV2,
  TextIntegrationAddModal,
  textIntegrationsV2,
} from '@capturi/integrations'
import { useOrganization } from '@capturi/stores'
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { ReactElement, useState } from 'react'
import { RiArrowRightSLine } from 'react-icons/ri'
import AddBoostAi from './boostAi/AddBoostAi'
import AddDixa from './dixa/AddDixa'
import AddEtrack1 from './etrack1/AddEtrack1'
import AddFocalscope from './focalscope/AddFocalscope'
import AddFocalscopeSftp from './focalscopeSftp/AddFocalscopeSftp'
import AddGenesysChat from './genesysChat/AddGenesysChat'
import { getTextColor } from './getTextColor'
import AddMock from './mock/AddMock'
import AddZendesk from './zendesk/AddZendesk'

type Props = { onClose: () => void }

type Integrations =
  | 'zendesk'
  | 'etrack1'
  | 'focalscope'
  | 'focalscopeSftp'
  | 'dixa'
  | 'mock'
  | 'genesys'
  | 'genesysChat'
  | 'boostAi'
  | IntegrationsV2

type Integration = {
  name: string
  key: Integrations
  color: string
  configV2: boolean
}

const textIntegrations: (Integration | IntegrationV2)[] = [
  {
    color: '#649a3d',
    key: 'etrack1',
    name: 'eTrack1',
    configV2: false,
  },
  {
    color: '#3a8481',
    key: 'focalscope',
    name: 'FocalScope',
    configV2: false,
  },
  {
    color: '#3a8481',
    key: 'focalscopeSftp',
    name: 'FocalScope Sftp',
    configV2: false,
  },
  {
    color: '#5644d8',
    key: 'dixa',
    name: 'Dixa',
    configV2: false,
  },

  {
    color: '#FF4F1F',
    key: 'genesysChat',
    name: 'Genesys Chat',
    configV2: false,
  },
  {
    color: '#59195D',
    key: 'boostAi',
    name: 'BoostAi',
    configV2: false,
  },
  ...textIntegrationsV2,
]

const AddImporterModal: React.FC<Props> = ({ onClose }) => {
  const [add, setAdd] = useState<Integrations | null>(null)
  const [addV2, setAddV2] = useState<IntegrationV2 | null>(null)
  const { hasSuperPowers } = useCurrentUser()
  const { name: organizationName, uid: organizationUid } = useOrganization()

  const renderIntegrationButton = (config: Integration): ReactElement => {
    return (
      <Flex
        justifyContent="space-between"
        alignItems="center"
        as="button"
        p="2"
        _hover={{
          bg: 'primary.50',
        }}
        width="100%"
        borderColor="border.light"
        borderBottomWidth="1px"
        key={config.key}
        onClick={() =>
          config.configV2
            ? setAddV2(config as IntegrationV2)
            : setAdd(config.key)
        }
      >
        <Flex alignItems="center">
          <Box
            w="28px"
            h="28px"
            fontSize="1.2rem"
            bg={config.color}
            mr="4"
            borderRadius="4"
            justifyContent="center"
            alignItems="center"
            color={getTextColor(config.color)}
          >
            {config.name[0]}
          </Box>
          {config.name}
        </Flex>
        <RiArrowRightSLine color="gray" size="25" />
      </Flex>
    )
  }

  if (add === 'zendesk') return <AddZendesk onClose={onClose} />
  if (add === 'etrack1') return <AddEtrack1 onClose={onClose} />
  if (add === 'focalscope') return <AddFocalscope onClose={onClose} />
  if (add === 'focalscopeSftp') return <AddFocalscopeSftp onClose={onClose} />
  if (add === 'dixa') return <AddDixa onClose={onClose} />
  if (add === 'genesysChat') return <AddGenesysChat onClose={onClose} />
  if (add === 'mock') return <AddMock onClose={onClose} />
  if (add === 'boostAi') return <AddBoostAi onClose={onClose} />

  if (addV2)
    return (
      <TextIntegrationAddModal
        integrationType={addV2.key}
        organizationName={organizationName}
        organizationUid={organizationUid}
        url={addV2.url}
        onClose={onClose}
      />
    )

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <Flex direction="column">
            <ModalHeader>
              <Trans>Add Importer</Trans>
            </ModalHeader>
          </Flex>
          <ModalCloseButton />
          <ModalBody>
            {textIntegrations.map(renderIntegrationButton)}

            {hasSuperPowers &&
              renderIntegrationButton({
                color: '#216D97',
                key: 'mock',
                name: 'Mock integration',
                configV2: false,
              })}
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default AddImporterModal
