import { type FC } from 'react'
import { BooleanField } from './BooleanField'
import { ChipsField } from './ChipsField'
import type { ConfigField } from './ConfigField'
import { Multiselect } from './Multiselect'
import { NumberField } from './NumberField'
import { SingleSelect } from './SingleSelect'
import TextField from './TextField'

type Props = {
  field: ConfigField
  value: unknown
  onChange: (value: unknown) => void
  isDisabled?: boolean
}

export const Field: FC<Props> = ({
  field: fieldProps,
  onChange,
  value,
  isDisabled,
}) => {
  const { key, ...field } = fieldProps
  switch (field.type) {
    case 'boolean':
      return (
        <BooleanField
          key={key}
          {...field}
          fieldKey={key}
          onChange={onChange}
          value={value as boolean}
          isDisabled={isDisabled}
        />
      )
    case 'text':
      return (
        <TextField
          key={key}
          {...field}
          fieldKey={key}
          onChange={onChange}
          value={value as string}
          isDisabled={isDisabled}
        />
      )
    case 'number':
      return (
        <NumberField
          key={key}
          {...field}
          fieldKey={key}
          onChange={onChange}
          value={value as number}
          isDisabled={isDisabled}
        />
      )
    case 'singleSelect':
      return (
        <SingleSelect
          key={key}
          {...field}
          fieldKey={key}
          onChange={onChange}
          value={value as string}
          isDisabled={isDisabled}
        />
      )
    case 'multiselect':
      return (
        <Multiselect
          key={key}
          {...field}
          fieldKey={key}
          onChange={onChange}
          value={value as string[]}
          isDisabled={isDisabled}
        />
      )
    case 'chips':
      return (
        <ChipsField
          key={key}
          {...field}
          fieldKey={key}
          onChange={onChange}
          value={value as string[]}
          isDisabled={isDisabled}
        />
      )

    default:
      return <div />
  }
}
