import { useInput } from '@capturi/react-utils'
import request from '@capturi/request/src/request'
import { useOrganization } from '@capturi/stores'
import { Button } from '@capturi/ui-components'
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { subDays } from 'date-fns'
import React, { useState } from 'react'

import { useAddDixaImporter } from './useDixaImporter'

type Props = {
  onClose: () => void
}

const AddDixa: React.FC<Props> = ({ onClose }) => {
  const [state, handleSetState] = useInput({
    token: '',
  })

  const { mutate: addImporter, isPending } = useAddDixaImporter()
  const { name } = useOrganization()
  const [testInProgress, setTestInProgress] = useState(false)
  const [testError, setTestError] = useState('')

  const handleAdd = async (): Promise<void> => {
    setTestInProgress(true)
    setTestError('')

    try {
      await request.post('importer/dixa/test-auth', {
        json: { auth: state },
      })

      addImporter(
        {
          auth: state,
          friendlyName: `${name} - Dixa`,
          continuationToken: subDays(new Date(), 10),
        },
        {
          onSuccess: () => {
            onClose()
          },
        },
      )
    } catch (_error) {
      setTestInProgress(false)
      setTestError('Invalid authentication')
    }
  }
  const inProgress = isPending || testInProgress
  return (
    <Modal isOpen={true} onClose={onClose} closeOnEsc={!inProgress}>
      <ModalOverlay>
        <ModalContent>
          <Flex direction="column">
            <ModalHeader>
              <Trans>Dixa importer</Trans>
            </ModalHeader>
          </Flex>
          {!inProgress && <ModalCloseButton />}
          <ModalBody>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel htmlFor="Token">
                  <Trans>Token</Trans>
                </FormLabel>
                <Input
                  isDisabled={isPending}
                  placeholder={t`Token`}
                  {...handleSetState.token}
                />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <div>
              <Stack direction="row" spacing={4}>
                <Button secondary isDisabled={inProgress} onClick={onClose}>
                  <Trans>Cancel</Trans>
                </Button>
                <Button
                  primary
                  isLoading={inProgress}
                  mr={3}
                  onClick={handleAdd}
                >
                  <Trans>Add Dixa importer</Trans>
                </Button>
              </Stack>
              <Text textAlign="end" color="red">
                {testError}
              </Text>
            </div>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default AddDixa
