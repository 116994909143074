import { type FC } from 'react'
import { textIntegrationsV2 } from '../integrations'
import { useListTextImporter } from '../useConfig'
import { DataLoaderContainer } from './DataLoaderContainer'

export const Single: FC<{ url: string; type: string }> = ({ type, url }) => {
  const { data, isLoading, error } = useListTextImporter(type)

  if (isLoading) return null
  if (error) return null

  return (data || []).map((config) => (
    <DataLoaderContainer
      config={config}
      key={config.uid}
      integrationUid={config.uid}
      integrationType={type}
      url={url}
    />
  ))
}

export const TextConfigV2: FC = () => {
  return textIntegrationsV2.map(({ url, key }, idx) => (
    <Single url={url} type={key} key={idx} />
  ))
}
